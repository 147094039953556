import { Fragment, useEffect, useState, useCallback } from 'react';
import { Card, Row, Container, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TapeCell from './TapeCell';
import TapeHead from './TapeHead';

import data from '../../data/exampleHistoryResponse.json';
import { useInterval } from '../../hooks/useInterval';
import TapeInfoWindow from './infoWindow/TapeInfoWindow';
import TapeControlButtons from './buttonSection/TapeControlButtons';

const Tape = ({ history, cellsShown = 20, halted }) => {
  const [tapeHistory, setHistory] = useState(data);
  const [tapeContents, setTapeContents] = useState(data.history[0].tape);
  const [moveNumber, setMoveNumber] = useState(0);
  const [tapeHeadLocation, setTapeHeadLocation] = useState({ current: 0, previous: -1 });
  const [firstSymbolIndex, setFirstSymbolIndex] = useState(0);
  const [lastSymbolIndex, setLastSymbolIndex] = useState(Math.min(cellsShown));
  const [simulationRunning, setSimulationRunning] = useState(false);

  // InfoTable
  const [displayingPreviousHead, setDisplayingPreviousHead] = useState(false);

  useEffect(() => {
    // Reset Tape state
    setMoveNumber(0);
    setTapeHeadLocation({ current: 0, previous: -1 });
    setHistory({ history: history });
    if (history[0]) {
      setTapeContents(history[0].tape);
    }
    setFirstSymbolIndex(0);
    setLastSymbolIndex(20); // hardcoded atm ...
    setSimulationRunning(false);

    console.log('attempted to set history');
    console.log(history);
  }, [history]);

  useInterval(
    () => {
      console.log(moveNumber);

      if (moveNumber >= Object.keys(tapeHistory.history).length - 1) {
        console.log('cleared');
        setSimulationRunning(false);
      }
      console.log('This will run every second!');
      performInstruction();
    },
    simulationRunning ? 1000 : null
  );

  const performInstruction = () => {
    console.log(`moveNo: ${moveNumber}, length-1: ${Object.keys(tapeHistory.history).length}`);
    if (moveNumber >= Object.keys(tapeHistory.history).length - 1) {
      console.log('at end of computation');
      return;
    }
    console.log(moveNumber);
    const moveNum = moveNumber + 1;
    console.log('history: ', tapeHistory.history);
    const { state, head, tape } = tapeHistory.history[moveNum];

    let prevLoc = -1;
    if (moveNum > 0) {
      prevLoc = tapeHistory.history[moveNum - 1].head;
    }

    setTapeContents(tape);

    console.log(
      `history length: ${Object.keys(tapeHistory.history).length}, move: '${moveNum}, location: ${
        tapeHeadLocation.current
      } ${tapeHeadLocation.previous}`
    );
    setMoveNumber(moveNum);
    setTapeHeadLocation({ current: head, previous: prevLoc });

    console.log('headlocation: ', head, ' prev: ', prevLoc);

    console.log(state);
  };

  const undoInstruction = () => {
    if (moveNumber < 1) {
      console.log('at start of computation');
      return;
    }

    const moveNum = moveNumber - 1;
    const { state, head, tape } = tapeHistory.history[moveNum];

    let prevLoc = 0;
    if (moveNum > 1) {
      prevLoc = tapeHistory.history[moveNum - 1].head;
    }

    setTapeContents(tape);

    console.log(
      `history length: ${Object.keys(tapeHistory.history).length}, move: '${moveNum}, location: ${
        tapeHeadLocation.current
      } ${tapeHeadLocation.previous}`
    );
    setMoveNumber(moveNum);
    setTapeHeadLocation({ current: head, previous: prevLoc });

    console.log(state);
  };

  const moveTapeForwards = () => {
    if (lastSymbolIndex >= tapeContents.length - 1) {
      console.log('Error not moved tape forward -> at end');
      return;
    }
    setFirstSymbolIndex(firstSymbolIndex + 1);
    setLastSymbolIndex(lastSymbolIndex + 1);
  };

  const moveTapeBackwards = () => {
    if (firstSymbolIndex <= 0) {
      console.log('Error not moved tape backward -> at front');
      return;
    }
    setFirstSymbolIndex(firstSymbolIndex - 1);
    setLastSymbolIndex(lastSymbolIndex - 1);
  };

  const currentTapeHeadLocation = () => {
    const location = tapeHeadLocation.current - firstSymbolIndex + 1;

    if (location > cellsShown + 1 || location < 1) {
      return 'hidden';
    }

    return location.toString();
  };

  const previousTapeHeadLocation = () => {
    const location = tapeHeadLocation.previous - firstSymbolIndex + 1;

    if (location > cellsShown + 1 || location < 1) {
      return 'hidden';
    }

    return location.toString();
  };

  const getCurrentState = () => {
    if (!tapeHistory.history[moveNumber]) {
      return '';
    }

    return tapeHistory.history[moveNumber].state;
  };

  const getPreviousState = () => {
    if (!tapeHistory.history[moveNumber - 1]) {
      return '';
    }

    return tapeHistory.history[moveNumber - 1].state;
  };

  const togglePreviousHeadVisible = useCallback(() => setDisplayingPreviousHead(!displayingPreviousHead), [
    displayingPreviousHead,
    setDisplayingPreviousHead
  ]);

  const toggleSimulationRunning = useCallback(() => {
    setSimulationRunning(!simulationRunning);
  }, [simulationRunning, setSimulationRunning]);

  const calculateNumMoves = () => {
    if (tapeHistory.history) {
      return Object.keys(tapeHistory.history).length - 1;
    } else {
      return 'N/A';
    }
  };

  const resetSimulation = () => {
    setMoveNumber(0);
    setTapeHeadLocation({ current: 0, previous: -1 });
    setHistory({ history: history });
    if (history[0]) {
      setTapeContents(history[0].tape);
    }
    setFirstSymbolIndex(0);
    setLastSymbolIndex(20);
    setSimulationRunning(false);
  };

  const tapeFunctions = {
    moveTapeForwards: moveTapeForwards,
    moveTapeBackwards: moveTapeBackwards,
    undoInstruction: undoInstruction,
    performInstruction: performInstruction,
    togglePreviousHead: togglePreviousHeadVisible,
    toggleSimulation: toggleSimulationRunning,
    reset: resetSimulation
  };

  return (
    <>
      <Container fluid="lg">
        <Row>
          <Col lg={8}>
            <Card>
              <Card.Body>
                <div className="tape-grid">
                  {tapeContents.map((value, index) => {
                    if (index >= firstSymbolIndex && index <= lastSymbolIndex) {
                      return <TapeCell key={index} value={value} />;
                    }
                    return <Fragment></Fragment>;
                  })}
                  <TapeHead isCurrent={true} location={currentTapeHeadLocation()} state={getCurrentState()} />
                  {displayingPreviousHead && (
                    <TapeHead
                      isCurrent={false}
                      location={previousTapeHeadLocation()}
                      state={getPreviousState()}
                    />
                  )}
                </div>
              </Card.Body>
              <Card.Footer>
                <TapeControlButtons
                  previousVisible={displayingPreviousHead}
                  tapeFunctions={tapeFunctions}
                  simulationRunning={simulationRunning}
                />
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <TapeInfoWindow
              moveNumber={moveNumber}
              tapeHeadLocation={tapeHeadLocation}
              numMoves={calculateNumMoves}
              firstCellIndex={firstSymbolIndex}
              lastCellIndex={lastSymbolIndex}
              halted={halted}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

Tape.defaultProps = {
  cellsShown: 20
};

Tape.propTypes = {
  cellsShown: PropTypes.number.isRequired
};

export default Tape;
