import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';

const HaltedInformation = ({ haltedInfo }) => {
  const alert = () => {
    if (!haltedInfo) {
      return;
    }

    const { halted, inAcceptanceState } = haltedInfo;
    if (!halted) {
      return <Alert variant="warning">Machine has not halted, try running the computation longer.</Alert>;
    } else if (inAcceptanceState) {
      return <Alert variant="success">Machine has halted in accepting state.</Alert>;
    } else {
      return <Alert variant="danger">Machine has halted due to an error or undefined transition.</Alert>;
    }
  };

  return <Fragment>{alert()}</Fragment>;
};

export default HaltedInformation;
