import React from 'react';
import { Card, Button } from 'react-bootstrap';

const MachineCard = ({ cardInfo, loadMachine }) => {
  const { data, _id } = cardInfo;
  return (
    <>
      <Card key={_id}>
        <Card.Body>
          <Card.Title>{data.name}</Card.Title>
          <Card.Subtitle>{data.author}</Card.Subtitle>
          <Card.Text>{data.description}</Card.Text>
          <Button onClick={() => loadMachine(_id)}>Load</Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default MachineCard;
