import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const InitialStateDropdown = ({ states, initialState, updateInitialState }) => {
  const [selState, setSelState] = useState(initialState);

  const handleSelStateChange = e => {
    console.log(`State changed to: ${e.target.value}`);
    setSelState(e.target.value);
    updateInitialState(e.target.value);
  };

  const stateDropdown = () => {
    console.log(states);

    return (
      <Form style={{ height: '100%' }}>
        <Form.Group>
          <Form.Label>Initial State</Form.Label>
          <Form.Control
            style={{ width: '50%' }}
            className="mx-auto"
            as="select"
            value={selState}
            onChange={e => handleSelStateChange(e)}
          >
            {states.map(state => {
              return (
                <option key={state} value={state}>
                  {state}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  return <div className="ml-2">{stateDropdown()}</div>;
};

export default InitialStateDropdown;
