import React, { Fragment } from 'react';
import MachineInput from './MachineInput';
import MaxNumStepsInput from './MaxNumStepsInput';

const OtherTab = ({ otherFunctions }) => {
  return (
    <Fragment>
      <MachineInput updateInput={otherFunctions.updateInput} />
      <MaxNumStepsInput updateMaxNumSteps={otherFunctions.updateMaxNumSteps} />
    </Fragment>
  );
};

export default OtherTab;
/*

    Max Num Moves -> input -> num only! check
    Initial State -> Dropdown from states, maybe add to states tab
    Acceptance States -> Toggle on States tab
    Input -> In misc tab as well
    Save -> modal with required info + password
*/
