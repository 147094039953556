import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, Card } from 'react-bootstrap';

const TapeControlButtons = ({ tapeFunctions, previousVisible, simulationRunning }) => {
  return (
    <Card>
      <ButtonToolbar>
        <Button
          className="mr-3"
          variant={previousVisible ? 'danger' : 'success'}
          onClick={tapeFunctions.togglePreviousHead}
        >
          {previousVisible ? 'Hide' : 'Show'} Previous
        </Button>
        <ButtonGroup className="ml-3 mr-3">
          <Button variant="secondary" onClick={tapeFunctions.moveTapeBackwards}>
            &lt; Tape
          </Button>
          <Button variant="secondary" onClick={tapeFunctions.moveTapeForwards}>
            Tape &gt;
          </Button>
        </ButtonGroup>

        <ButtonGroup className="ml-3 mr-3">
          <Button variant="secondary" onClick={tapeFunctions.undoInstruction}>
            &lt; Undo
          </Button>
          <Button variant="secondary" onClick={tapeFunctions.performInstruction}>
            Next &gt;
          </Button>
        </ButtonGroup>

        <ButtonGroup className="ml-3">
          <Button variant="secondary" onClick={tapeFunctions.reset}>
            Reset
          </Button>
          <Button variant="primary" onClick={tapeFunctions.toggleSimulation}>
            {simulationRunning ? 'Pause' : 'Play'}
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Card>
  );
};

export default TapeControlButtons;
