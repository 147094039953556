import PropTypes from 'prop-types';

const TapeCell = props => {
  return (
    <div className="tape-cell ">
      <span className="text-align-middle">{props.value}</span>
    </div>
  );
};

TapeCell.propTypes = {
  value: PropTypes.string.isRequired
};

export default TapeCell;
