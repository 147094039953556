import React, { useEffect, useState } from 'react';
import API from './api/api';
import MachineSetup from './components/MachineSetup/MachineSetup';
import Tape from './components/Tape/Tape';

import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const title = 'Turing Machine Simulator';

  const [initialState, setInitialState] = useState('q0');
  const [acceptanceStates, setAcceptanceStates] = useState([]);
  const [maxNumMoves, setMaxNumMoves] = useState(30);
  const [input, setInput] = useState('00000');
  const [machine, setMachine] = useState({});
  const [history, setHistory] = useState({ history: [] });
  const [askedForFetch, setAskedForFetch] = useState(false);
  const [halted, setHalted] = useState(null);
  const [askedForSave, setAskedForSave] = useState(false);
  const [data, setData] = useState({});
  const [machines, setMachines] = useState([]);
  const [askedForMachines, setAskedForMachines] = useState(false);

  useEffect(() => {
    const fetchHistory = async () => {
      // Dodgy check on whether performing fetch is actually required, stops unnecessary fetches
      if (!askedForFetch) {
        console.log('No fetch currently needed.');
        return;
      }

      try {
        setAskedForFetch(false);
        const { transitions } = machine;

        if (!transitions) {
          console.log('aborting fetch -- transitions undefined');
          return;
        }
        const reqBody = {
          machine: { transitions: transitions },
          input,
          acceptanceStates,
          maxNumMoves,
          initialState
        };
        console.log(reqBody);
        const res = await API.getTuringMachineHistory(reqBody);
        console.log('printing resHistory', res.history);

        setHalted({ ...res.haltedInformation });
        setHistory({ ...res.history });
      } catch (e) {
        console.log(e, 'something bad happened in fetch');
      }
    };

    fetchHistory().then('attempted to fetch history');
  }, [machine, input, maxNumMoves, initialState, acceptanceStates, askedForFetch]);

  useEffect(() => {
    const saveMachine = async () => {
      if (!askedForSave) {
        console.log('No save needed');
        return;
      }

      try {
        setAskedForSave(false);
        const { transitions } = machine;

        const reqBody = {
          machine: { transitions: transitions },
          input,
          acceptanceStates,
          maxNumMoves,
          initialState,
          data
        };

        console.log(reqBody);
        const res = await API.saveTuringMachine(reqBody);
        alert(`Response status: ${res.response}`);

        //console.log('Response from save:', res);
      } catch (e) {
        console.log(e, 'error in saveMachine');
      }
    };

    saveMachine().then('attempted to save Machine');
  }, [machine, askedForSave, input, maxNumMoves, acceptanceStates, data, initialState]);

  useEffect(() => {
    const fetchMachines = async () => {
      if (!askedForMachines) {
        console.log('No machines needed');
        return;
      }

      try {
        setAskedForMachines(false);

        const res = await API.getTuringMachines();
        console.log('machines being set to', res.machines);
        setMachines(res.machines);
      } catch (e) {
        console.log(e, 'error in fetchMachines');
      }
    };

    fetchMachines().then('attempted to fetch machines');
  }, [askedForMachines]);

  const changeHandlers = {
    handleInputChange: updatedInput => {
      setInput(updatedInput);
    },
    handleAcceptanceStateChange: updatedAcceptanceStates => {
      setAcceptanceStates([...updatedAcceptanceStates]);
    },
    handleMaxNumMovesChange: updatedMaxNumMoves => {
      console.log(`updating maxNumMoves to ${updatedMaxNumMoves}`);
      setMaxNumMoves(updatedMaxNumMoves);
    },
    handleInitialStateChange: updatedInitialState => {
      setInitialState(updatedInitialState);
    },
    handleMachineChange: updatedMachine => {
      setMachine({ ...updatedMachine });
      setAskedForFetch(true);
      console.log('updatedMachine =');
      console.log(updatedMachine);
    },
    handleDataChange: updatedData => {
      setData({ ...updatedData });
      setAskedForSave(true);
      console.log('updatedData');
    },
    askForMachines: () => {
      setAskedForMachines(true);
    }
  };

  return (
    <div className="App">
      <div className="content">
        <h3> {title}</h3>
      </div>

      <Container>
        <Row>
          <Tape history={history} halted={halted} />
        </Row>
        <MachineSetup changeHandlers={changeHandlers} machines={machines} />
      </Container>
    </div>
  );
}

export default App;
