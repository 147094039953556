import React from 'react';

const TransitionTableElement = ({ state, symbol, newState, newSymbol, dir, handleClick }) => {
  if (!newState || !newSymbol || !dir) {
    return <td key={`${state}_${symbol}`} onClick={() => handleClick(state, symbol)}></td>;
  }

  //console.log('hello', state);
  return (
    <td key={`${state}_${symbol}`} onClick={() => handleClick(state, symbol)}>
      ({newState}, {newSymbol}, {dir})
    </td>
  );
};

export default TransitionTableElement;

/**
 *
 * Transition[symbol][state]? -> Key [a,b], key {a, b}, keys a,b? options...
 *
 * Place at row x col y
 * or flipped -> col x row y
 *
 * Generate headers & flip icon!
 *
 * -> Display simple { (q0, 1) -> (q1,0,R) } for each element in the table
 *
 * Scroll!
 *
 * Simple edit modal popup on click/ remove option
 *
 * Simple way to add new transitions ...
 *
 * Only allow 'already defined' states, symbols. { Start with defaults, then customisable }
 * Modal -> dropdowns of ^ to make life easier.
 *
 * -> Run calculation on server option -> update machine
 *
 */
