import PropTypes from 'prop-types';

const TapeHead = props => {
  const type = props.isCurrent ? 'current' : 'previous';

  if (!props.isCurrent) {
    console.log(` PREVIOUS HEAD: loc:${props.location} state" ${props.state}`);
  }
  return <div className={`tape-head-${type} single-col-${props.location}`}>{props.state || 'N/A'}</div>;
};

TapeHead.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export default TapeHead;
