import React from 'react';
import { Tab, Button, Row, Container, Col, Nav } from 'react-bootstrap';
import OtherTab from './other/OtherTab';
import SaveLoadTab from './save-load/SaveLoadTab';
import StateSetupTab from './states/StateSetupTab';
import SymbolSetupTab from './symbols/SymbolSetupTab';
import TransitionTableTab from './transitions/TransitionTableTab';

const MachineSetupTabSection = ({
  states,
  symbols,
  instructionMap,
  initialState,
  instructionMapInitialised,
  functions,
  runMachineHandler,
  machines
}) => {
  //const [key, setKey] = useState('states');

  return (
    <>
      <Container fluid className="mt-3">
        <Tab.Container sm={12}>
          <Row>
            <Col sm={2} bg="dark">
              <Button variant="primary" className="mb-2" onClick={runMachineHandler}>
                Update Machine
              </Button>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="states">States</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="symbols">Symbols</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="transitions">Transitions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="other">Other</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="save-load">Save/Load</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey={'states'}>
                  <StateSetupTab
                    states={states}
                    initialState={initialState}
                    stateFunctions={functions.stateFunctions}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={'symbols'}>
                  <SymbolSetupTab symbols={symbols} symbolFunctions={functions.symbolFunctions} />
                </Tab.Pane>
                <Tab.Pane eventKey={'transitions'}>
                  <TransitionTableTab
                    states={states}
                    symbols={symbols}
                    instructionMap={instructionMap}
                    instructionMapInitialised={instructionMapInitialised}
                    transitionFunctions={functions.transitionFunctions}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={'other'}>
                  <OtherTab otherFunctions={functions.otherFunctions} />
                </Tab.Pane>
                <Tab.Pane eventKey={'save-load'}>
                  <SaveLoadTab saveLoadFunctions={functions.saveLoadFunctions} machines={machines} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default MachineSetupTabSection;
