import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

// Structure inspired by https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2

const SaveModal = ({ save }) => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({ name: '', description: '', author: '', password: '' });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const findFormErrors = () => {
    const { name, description, author, password } = data;

    const newErrors = {};

    if (!name || name === '') {
      newErrors.name = 'missing name';
    } else if (name.length > 50) {
      newErrors.name = 'name is too long';
    }
    if (!description || description === '') {
      newErrors.description = 'missing description';
    }

    if (!author || author === '') {
      newErrors.author = 'missing Author, try Anonymous';
    }

    if (!password || password === '') {
      newErrors.password = 'missing password';
    }

    return newErrors;
  };

  const handleSubmit = e => {
    console.log('submit');
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors -> attempting to save
      alert('Attempting to save');
      save(data.name, data.description, data.author, data.password);

      // save
    }
    handleClose();
  };

  const setField = (field, value) => {
    setData({ ...data, [field]: value });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null
      });
  };

  return (
    <>
      <div className="saveModalButton">
        <Button variant="primary" className="float-left ml-3" onClick={handleShow}>
          Save
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Save Machine</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={e => handleSubmit(e)}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={data.name}
                  onChange={e => setField('name', e.target.value)}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={data.description}
                  onChange={e => setField('description', e.target.value)}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Author</Form.Label>
                <Form.Control
                  type="text"
                  value={data.author}
                  placeholder="Anonymous"
                  onChange={e => setField('author', e.target.value)}
                  isInvalid={!!errors.author}
                />
                <Form.Control.Feedback type="invalid">{errors.author}</Form.Control.Feedback>{' '}
              </Form.Group>
              <Form.Group>
                <Form.Label>Admin Password</Form.Label>
                <Form.Control
                  type="password"
                  value={data.password}
                  onChange={e => setField('password', e.target.value)}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>{' '}
              </Form.Group>
              <Button type="submit">Save Machine</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SaveModal;
