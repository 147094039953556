import { Modal, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import MachineCard from './MachineCard';

const BrowseModal = ({ machines, load, fetchMachines }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    console.log('attempting fetch from model');
    try {
      fetchMachines();
    } catch (e) {
      console.log(e);
    }
    setShow(true);
  };
  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Browse Machines
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Browse Machines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(machines).map(key => {
            return <MachineCard key={key} loadMachine={load} cardInfo={machines[key]} />;
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BrowseModal;
