import React from 'react';
import { Alert } from 'react-bootstrap';
import HaltedInformation from './HaltedInformation';

const TapeInfoWindow = ({
  moveNumber,
  numMoves,
  tapeHeadLocation,
  firstCellIndex,
  lastCellIndex,
  halted
}) => {
  return (
    <Alert variant="info" className="text-left ml-2 flex" style={{ height: '100%' }}>
      <Alert.Heading>Simulator Information</Alert.Heading>
      <span>
        Move: {moveNumber} / {numMoves()} <br />
      </span>
      <span>
        Head position: {tapeHeadLocation.current} <br />
      </span>
      <span>
        Tape visible from cell {firstCellIndex} to {lastCellIndex}{' '}
      </span>
      <br />
      <br />
      <HaltedInformation style={{ height: '100%' }} haltedInfo={halted} />
    </Alert>
  );
};

export default TapeInfoWindow;
