import { useState } from 'react';
import { Form } from 'react-bootstrap';

const StateInput = ({ inputChangeHandler }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = e => {
    setInputValue(e.target.value);
    inputChangeHandler(e.target.value);
  };
  return (
    <Form>
      <Form.Group>
        <Form.Label>New State: </Form.Label>
        <Form.Control
          type="text"
          value={inputValue}
          onChange={e => {
            handleChange(e);
          }}
        ></Form.Control>
      </Form.Group>
    </Form>
  );
};

export default StateInput;
