import React, { useState, useCallback } from 'react';
import { Card, Button } from 'react-bootstrap';
import TransitionTableElement from './TransitionTableElement';
import InstructionModal from './InstructionModal';

const TransitionTableTab = ({
  states,
  symbols,
  instructionMap,
  instructionMapInitialised,
  transitionFunctions
}) => {
  const [isTableFlipped, setIsTableFlipped] = useState(false);
  const [showingInstructionModal, setShowingInstructionModal] = useState(false);
  const [instructionModalData, setInstructionModalData] = useState({
    newState: '',
    newSymbol: '',
    dir: ''
  });

  const toggleFlippedTable = useCallback(() => setIsTableFlipped(!isTableFlipped), [
    isTableFlipped,
    setIsTableFlipped
  ]);

  const spawnModal = (state, symbol) => {
    const { newState, newSymbol, dir } = instructionMap[state][symbol];
    const data = { state, symbol, newState, newSymbol, dir };
    setShowingInstructionModal(true);
    setInstructionModalData(data);
  };

  const handleModalClose = (valid, { state, symbol, newState, newSymbol, dir }) => {
    setShowingInstructionModal(showingInstructionModal => !showingInstructionModal);

    console.log(
      `valid: ${valid}, state: ${state}, symbol: ${symbol}, newState: ${newState}, newSymbol: ${newSymbol}, dir: ${dir}`
    );
    if (valid) {
      transitionFunctions.addInstruction({ state, symbol, newState, newSymbol, dir });
    } else {
      console.log('Error invalid selection');
    }
  };

  const displayInstructions = instructionMap => {
    if (!instructionMap === {}) {
      return;
    }

    // Normal way, add Table inversion
    if (!isTableFlipped) {
      return (
        <table>
          <thead>
            <tr>
              <th onClick={toggleFlippedTable}>Flip</th>
              {symbols.map(symbol => (
                <th key={symbol}>{symbol}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {states.map(state => {
              return (
                <tr key={state}>
                  <th key={state}>{state}</th>
                  {instructionMap &&
                    symbols.map(symbol => {
                      const obj = { state, symbol, ...instructionMap[state][symbol] };
                      return (
                        <TransitionTableElement
                          key={`${state}_${symbol}`}
                          {...obj}
                          handleClick={spawnModal}
                        ></TransitionTableElement>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <table>
          <thead>
            <tr>
              <th onClick={toggleFlippedTable}>Flip</th>
              {states.map(state => (
                <th key={state}>{state}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {symbols.map(symbol => {
              return (
                <tr key={symbol}>
                  <th key={symbol}>{symbol}</th>
                  {states.map(state => {
                    console.log(`state: ${state}, symbol: ${symbol}, ${instructionMap[state][symbol]}`);
                    const obj = { state, symbol, ...instructionMap[state][symbol] };
                    return (
                      <TransitionTableElement
                        key={`${state}_${symbol}`}
                        {...obj}
                        handleClick={spawnModal}
                      ></TransitionTableElement>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  };

  return (
    <Card>
      {instructionMapInitialised && <div className="table">{displayInstructions(instructionMap)}</div>}
      <Button onClick={transitionFunctions.initialiseInstructionMap}>
        {instructionMapInitialised ? 'Reset' : 'Initialise'} Transition Table
      </Button>
      <InstructionModal
        show={showingInstructionModal}
        instructionData={instructionModalData}
        states={states}
        symbols={symbols}
        handleClose={handleModalClose}
      />
    </Card>
  );
};

export default TransitionTableTab;
