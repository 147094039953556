import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';

const MaxNumStepsInput = ({ updateMaxNumSteps }) => {
  const [inputValue, setInputValue] = useState(30);

  const handleChange = e => {
    const isInteger = Number.isInteger(Number(e.target.value));
    const value = isInteger ? e.target.value : 30;
    setInputValue(value);
    updateMaxNumSteps(value);
  };

  const doubleNumber = () => {
    if (Number.isInteger(Number(inputValue))) {
      // TODO: HARD LIMIT!!!
      const doubledValue = 2 * inputValue;
      setInputValue(doubledValue);
      updateMaxNumSteps(doubledValue);
    }
  };

  return (
    <>
      <Form className="text-left ml-2 col-sm-6">
        <Form.Group>
          <Form.Label>Maximum Number of Steps for computation:</Form.Label>
          <Form.Control
            type="number"
            value={inputValue}
            onChange={e => {
              handleChange(e);
            }}
          ></Form.Control>
        </Form.Group>
        <Button style={{ height: '50%' }} className="col-sm-6 center" onClick={doubleNumber}>
          Double
        </Button>
      </Form>
    </>
  );
};

export default MaxNumStepsInput;
