import React, { Fragment } from 'react';
import SaveModal from './SaveModal';
import BrowseModal from './BrowseModal';

const SaveLoadTab = ({ saveLoadFunctions, machines }) => {
  return (
    <Fragment>
      <SaveModal save={saveLoadFunctions.save} />
      <BrowseModal
        load={saveLoadFunctions.load}
        fetchMachines={saveLoadFunctions.fetchMachines}
        machines={machines}
      />
    </Fragment>
  );
};

export default SaveLoadTab;
