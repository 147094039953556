import React from 'react';
import { Card, ListGroup, Col, Row, Container } from 'react-bootstrap';
import SymbolListItem from './SymbolListItem';

const SymbolSetupTab = ({ symbols, symbolFunctions }) => {
  return (
    <Card className="text-center align-center my-auto mx-auto">
      <Container>
        <ListGroup>
          <Row className="row-cols-xs-2">
            <Col>
              {symbols.map(symbol => {
                return <SymbolListItem key={symbol} symbol={symbol} symbolFunctions={symbolFunctions} />;
              })}
            </Col>
            <Col>
              <SymbolListItem key="new" symbolFunctions={symbolFunctions} />
            </Col>
          </Row>
        </ListGroup>
      </Container>
    </Card>
  );
};

export default SymbolSetupTab;
