// Structure of this API wrapper inspired from https://github.com/CharlieHewitt/IndustrialTeamProject/blob/dev/client/src/api.js

const URL =
  // !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  // ?
  // 'http://localhost:4000';
  //:
  'https://dundee-turing-simulator.herokuapp.com';

const fetchJSON = async (url, reqData = {}) => {
  if (reqData)
    reqData.headers = {
      'Content-Type': 'application/json'
    };
  if (reqData.body) reqData.body = JSON.stringify(reqData.body);
  let res = await fetch(url, reqData);
  res = await res.json();
  return res;
};

const API = {
  // Run Turing Machine
  async getTuringMachineHistory({ machine, input, acceptanceStates, initialState, maxNumMoves }) {
    console.log('fetching history');
    console.log({ machine, input, acceptanceStates, maxNumMoves, initialState });
    let res = await fetchJSON(`${URL}/turing`, {
      method: 'POST',
      body: { machine, input, acceptanceStates, maxNumMoves, initialState }
    });
    console.log('response:', res);
    return res;
  },

  async saveTuringMachine({ machine, input, acceptanceStates, initialState, maxNumMoves, data }) {
    console.log('attempting to save machine');
    console.log({ machine, input, acceptanceStates, initialState, maxNumMoves, data });

    let res = await fetchJSON(`${URL}/dbMachines/save`, {
      method: 'POST',
      body: { machine, input, acceptanceStates, initialState, maxNumMoves, data }
    });
    console.log('response', res);
    return res;
  },

  async getTuringMachines() {
    console.log('getting Turing Machines');

    let res = await fetchJSON(`${URL}/dbMachines/get`, {
      method: 'GET'
    });

    console.log('response', res);
    return res;
  }
};
export default API;
