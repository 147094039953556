import React, { useEffect, useState, Fragment } from 'react';
import { Button, ButtonToolbar, Container, Row, Modal, Form } from 'react-bootstrap';
// General Structure inspired by https://www.digitalocean.com/community/tutorials/react-modal-component (Converted to use hooks and func components)

const InstructionModal = ({ show, handleClose, instructionData, states, symbols }) => {
  const [selState, setSelState] = useState(symbols[0]);
  const [selSymbol, setSelSymbol] = useState(states[0]); //instructionData.newSymbol);
  const [selDir, setSelDir] = useState('L');
  const [defaultSelections] = useState({ state: states[0], symbol: symbols[0], dir: 'L' });
  //const displayClassName = show ? 'instruction-modal display-block' : 'instruction-modal display-none';

  useEffect(() => {
    setSelState(instructionData.newState || defaultSelections.state);
    setSelSymbol(instructionData.newSymbol || defaultSelections.symbol);
    setSelDir(instructionData.dir || defaultSelections.dir);
  }, [instructionData, defaultSelections]);

  const handleSelStateChange = e => {
    console.log(`State changed to: ${e.target.value}`);
    setSelState(e.target.value);
  };
  const handleSelSymbolChange = e => {
    console.log(`Symbol changed to: ${e.target.value}`);
    setSelSymbol(e.target.value);
  };
  const handleSelDirChange = e => {
    console.log(`Dir chagned to: ${e.target.value}`);
    setSelDir(e.target.value);
  };

  const resetTransition = () => {
    handleClose(true, {
      state: instructionData.state,
      symbol: instructionData.symbol,
      newState: null,
      newSymbol: null,
      dir: null
    });
  };

  const saveTransition = () => {
    handleClose(true, {
      state: instructionData.state,
      symbol: instructionData.symbol,
      newState: selState,
      newSymbol: selSymbol,
      dir: selDir
    });
  };

  const stateDropdown = () => {
    return (
      <Form.Group className="mr-2 col-3">
        <Form.Label>State:</Form.Label>
        <Form.Control as="select" value={selState} onChange={e => handleSelStateChange(e)} name="state">
          {states.map(state => {
            return (
              <option key={state} value={state}>
                {state}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  const symbolDropdown = () => {
    return (
      <Form.Group className="col-3">
        <Form.Label>Symbol:</Form.Label>
        <Form.Control as="select" value={selSymbol} onChange={e => handleSelSymbolChange(e)} name="newSymbol">
          {symbols.map(symbol => {
            return (
              <option key={symbol} value={symbol}>
                {symbol}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  const directionDropdown = () => {
    return (
      <Form.Group className="col-2">
        <Form.Label>Direction:</Form.Label>
        <Form.Control as="select" value={selDir} onChange={e => handleSelDirChange(e)} name="dir">
          <option key={'left'} value={'L'}>
            L
          </option>
          <option key={'right'} value={'R'}>
            R
          </option>
        </Form.Control>
      </Form.Group>
    );
  };

  return (
    <Fragment>
      <Modal show={show} onHide={saveTransition}>
        <Modal.Header closeButton>
          Transition Editor: ({instructionData.state}, {instructionData.symbol})
        </Modal.Header>
        <Modal.Body>
          <Form className="row">
            <span className="col-3 my-auto">
              ({instructionData.state}, {instructionData.symbol}) =&gt;{' '}
            </span>
            {stateDropdown()}
            {symbolDropdown()}
            {directionDropdown()}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={resetTransition}>
            Reset
          </Button>
          <Button className="mr-2 ml-2" onClick={saveTransition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className={displayClassName}>
        <div className="modal-main">
          ({instructionData.state}, {instructionData.symbol}) =&gt; {stateDropdown()}
          {symbolDropdown()}
          {directionDropdown()}
          <Button className="mr-2 ml-2" onClick={saveTransition}>
            Save
          </Button>
          <Button variant="danger" onClick={resetTransition}>
            Reset
          </Button>
        </div>
      </div> */}
    </Fragment>
  );
};

export default InstructionModal;
