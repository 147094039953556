import React, { Fragment, useState } from 'react';
import { Button, ListGroupItem } from 'react-bootstrap';
import StateInput from './StateInput';

const StateListItem = ({ state, stateFunctions }) => {
  const [input, setInput] = useState('');

  const inputChangeHandler = str => {
    setInput(str);
  };

  const getContent = () => {
    let content = '';
    if (state) {
      content = (
        <Fragment>
          <span className="col-sm-2">{state} </span>
          <Button
            className="col-sm-5"
            variant={stateFunctions.isAcceptanceState(state) ? 'success' : 'danger'}
            onClick={() => {
              stateFunctions.handleAcceptanceStateToggle(state);
            }}
          >
            Acceptance State
          </Button>
          <Button
            className="col-sm-4 ml-sm-2"
            variant={'danger'}
            onClick={() => {
              stateFunctions.remove(state);
              console.log('Deleted ', state);
            }}
          >
            Remove
          </Button>
        </Fragment>
      );
    } else {
      content = (
        <Fragment>
          <StateInput inputChangeHandler={inputChangeHandler} />
          <Button
            className="col-sm-6"
            onClick={() => {
              console.log('stateInputText: ', state);
              if (input.length > 0) {
                stateFunctions.add(input);
                console.log('Added new');
              }
            }}
          >
            Add
          </Button>
        </Fragment>
      );
    }

    return content;
  };
  return <ListGroupItem key={state || 'new'}>{getContent()}</ListGroupItem>;
};

export default StateListItem;
