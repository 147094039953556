import React, { Fragment, useState } from 'react';
import { Button, ListGroupItem, Row } from 'react-bootstrap';
import SymbolInput from './SymbolInput';

const SymbolListItem = ({ symbol, symbolFunctions }) => {
  const [input, setInput] = useState('');

  const inputChangeHandler = str => {
    setInput(str);
  };

  const getContent = () => {
    let content = '';
    if (symbol) {
      content = (
        <Fragment>
          <Row>
            <span className="mr-3 my-auto col-sm-2">{symbol} </span>
            <Button
              className="col-sm-6"
              variant={'danger'}
              onClick={() => {
                symbolFunctions.remove(symbol);
                console.log('Deleted ', symbol);
              }}
            >
              Remove
            </Button>
          </Row>
        </Fragment>
      );
    } else {
      content = (
        <Fragment>
          <SymbolInput inputChangeHandler={inputChangeHandler} />
          <Button
            className="col-sm-6"
            onClick={() => {
              console.log('symbolInputText: ', symbol);
              if (input.length > 0) {
                symbolFunctions.add(input);
                console.log('Added new');
              }
            }}
          >
            Add
          </Button>
        </Fragment>
      );
    }

    return content;
  };
  return <ListGroupItem key={symbol || 'new'}>{getContent()}</ListGroupItem>;
};

export default SymbolListItem;
