import { React, useState } from 'react';
import MachineSetupTabSection from '../tabSections/MachineSetupTabSection';
import { Row } from 'react-bootstrap';

const MachineSetup = ({ changeHandlers, machines }) => {
  const [states, setStates] = useState(['q0', 'q1']); // ,'q2', 'q3']); //', 'q3']);
  const [symbols, setSymbols] = useState(['0', '1', 'B']);
  const [acceptanceStates, setAcceptanceStates] = useState([]);
  const [initialState, setInitialState] = useState('q0');

  const [instructionMap, setInstructionMap] = useState({ q0: {}, q1: {} });
  const [instructionMapInitialised, setInstructionMapInitialised] = useState(false);

  const instructionMapToRequestJson = () => {
    const machine = { transitions: [] };

    if (!instructionMapInitialised) {
      console.log('map not initialised');
      return {};
    }

    for (const state of states) {
      for (const symbol of symbols) {
        if (instructionMap[state][symbol]) {
          const { newState, newSymbol, dir } = instructionMap[state][symbol];
          machine.transitions.push({
            startState: state,
            startSymbol: symbol,
            newState,
            newSymbol,
            direction: dir
          });
        }
      }
    }

    return machine;
  };

  const reconstructInstructionMap = (states, symbols, transitions) => {
    const map = {};

    for (const state of states) {
      console.log(state);
      map[state] = {};

      console.log(map);
      for (const symbol of symbols) {
        map[state][symbol] = {};
        console.log(map);
      }
    }

    transitions.forEach(transition => {
      if (transition.newState && transition.newSymbol && transition.direction) {
        map[transition.startState][transition.startSymbol] = {
          newState: transition.newState,
          newSymbol: transition.newSymbol,
          dir: transition.direction
        };
      }
    });

    setInstructionMap({ ...map });
    setInstructionMapInitialised(true);
  };

  // Functions for modifying state in child components
  const setupFunctions = {
    stateFunctions: {
      add: state => {
        if (
          !states.find(value => {
            return value === state;
          })
        ) {
          const updatedStates = [...states, state];
          addStateToInstructionMap(state);
          setStates(updatedStates);
        } else {
          console.log('attempted to add duplicate state');
        }
      },
      remove: state => {
        if (initialState === state) {
          alert('Error: cannot remove initial state, select a new Initial state before removing');
          return;
        }

        if (isStateUsedInTransition(state)) {
          alert('Please remove the transition using this state before removing it.');

          console.log('Please remove the transition containing this state first.');
          return;
        }
        const filtered = states.filter(value => {
          return value !== state;
        });

        setupFunctions.stateFunctions.removeAcceptanceState(state);
        removeStateFromInstructionMap(state);
        console.log(states);
        console.log(filtered);

        setStates(filtered);
      },
      updateInitialState: state => {
        setInitialState(state);
      },
      addAcceptanceState: state => {
        if (
          !acceptanceStates.find(value => {
            return value === state;
          })
        ) {
          const updatedAcceptanceStates = [...acceptanceStates, state];
          setAcceptanceStates(updatedAcceptanceStates);
        }
      },
      removeAcceptanceState: state => {
        const filteredAcceptanceStates = acceptanceStates.filter(value => {
          return value !== state;
        });

        setAcceptanceStates(filteredAcceptanceStates);
      },
      handleAcceptanceStateToggle: state => {
        setupFunctions.stateFunctions.isAcceptanceState(state)
          ? setupFunctions.stateFunctions.removeAcceptanceState(state)
          : setupFunctions.stateFunctions.addAcceptanceState(state);
      },
      isAcceptanceState: state => {
        return acceptanceStates.find(value => {
          return value === state;
        });
      }
    },
    symbolFunctions: {
      add: symbol => {
        if (
          !symbols.find(e => {
            return e === symbol;
          })
        ) {
          const updatedSymbols = [...symbols, symbol];
          addSymbolToInstructionMap(symbol);
          setSymbols(updatedSymbols);
        } else {
          console.log('attempted to add duplicate symbol');
        }
      },
      remove: symbol => {
        if (isSymbolUsedInTransition(symbol)) {
          alert('Please remove the transition using this symbol before removing it.');
          console.log('Please remove transition before removing symbol');
          return;
        }
        const filtered = symbols.filter((value, index, arr) => {
          return value !== symbol;
        });
        removeSymbolFromInstructionMap(symbol);
        setSymbols(filtered);
      }
    },
    transitionFunctions: {
      addInstruction: ({ state, symbol, newState, newSymbol, dir }) => {
        const tempMap = instructionMap;
        console.log(`${state}, ${symbol}`);

        if (newState && newSymbol && dir) {
          tempMap[state][symbol] = { newState, newSymbol, dir };
        } else {
          tempMap[state][symbol] = {};
        }

        console.log(tempMap);

        setInstructionMap({ ...tempMap });
      },
      initialiseInstructionMap: () => {
        const map = {};

        for (const state of states) {
          console.log(state);
          map[state] = {};

          console.log(map);
          for (const symbol of symbols) {
            map[state][symbol] = {};
            console.log(map);
          }
        }

        setInstructionMap({ ...map });
        setInstructionMapInitialised(true);
      }
    },
    otherFunctions: {
      updateInput: str => {
        changeHandlers.handleInputChange(str);
        console.log(str);
      },
      updateMaxNumSteps: num => {
        changeHandlers.handleMaxNumMovesChange(num);
      }
    },
    saveLoadFunctions: {
      save: (name, description, author, password) => {
        console.log(name, description, author, password);
        runMachineHandler();
        changeHandlers.handleDataChange({
          name,
          description,
          author,
          password,
          states: states,
          symbols: symbols
        });
      },
      load: _id => {
        const loadedMachine = machines.find(m => {
          return m._id === _id;
        });

        console.log(loadedMachine, 'Loaded machine');
        console.log(machines);

        setStates(loadedMachine.data.states);
        setSymbols(loadedMachine.data.symbols);
        reconstructInstructionMap(
          loadedMachine.data.states,
          loadedMachine.data.symbols,
          loadedMachine.machine.transitions
        );
        setInitialState(loadedMachine.initialState);
        setAcceptanceStates(loadedMachine.acceptanceStates);
        changeHandlers.handleInputChange(loadedMachine.input);
        changeHandlers.handleMaxNumMovesChange(loadedMachine.maxNumMoves);
        runMachineHandler();

        console.log(loadedMachine, 'Loading:');
      },
      fetchMachines: () => {
        changeHandlers.askForMachines();
      }
    }
  };

  const runMachineHandler = () => {
    const machine = instructionMapToRequestJson();
    changeHandlers.handleInitialStateChange(initialState);
    changeHandlers.handleAcceptanceStateChange(acceptanceStates);
    changeHandlers.handleMachineChange(machine);
  };

  const isSymbolUsedInTransition = testedSymbol => {
    for (const state of states) {
      for (const symbol of symbols) {
        if (instructionMap[state][symbol]) {
          if (instructionMap[state][symbol].newSymbol === testedSymbol) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const isStateUsedInTransition = testedState => {
    console.log(instructionMap);
    for (const state of states) {
      for (const symbol of symbols) {
        if (instructionMap[state][symbol]) {
          // console.log('exists', instructionMap[state][symbol]);
          if (instructionMap[state][symbol].newState === testedState) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const addStateToInstructionMap = state => {
    const map = instructionMap;

    console.log(map);
    map[state] = {};

    for (const symbol of symbols) {
      map[state][symbol] = {};
    }

    console.log(map);
    setInstructionMap({ ...map });
  };

  const removeStateFromInstructionMap = state => {
    const map = instructionMap;
    console.log(map);
    delete map[state];
    console.log(map);

    setInstructionMap({ ...map });
  };

  const addSymbolToInstructionMap = symbol => {
    const map = instructionMap;

    for (const state of states) {
      map[state][symbol] = {};
    }

    setInstructionMap({ ...map });
  };

  const removeSymbolFromInstructionMap = symbol => {
    const map = instructionMap;

    for (const state of states) {
      delete map[state][symbol];
    }

    setInstructionMap({ ...map });
  };

  // Render
  return (
    <>
      <h4 className="mt-3"> Machine Setup</h4>
      <Row>
        <MachineSetupTabSection
          states={states}
          symbols={symbols}
          instructionMap={instructionMap}
          instructionMapInitialised={instructionMapInitialised}
          machines={machines}
          functions={setupFunctions}
          runMachineHandler={runMachineHandler}
        />
      </Row>
    </>
  );
};

export default MachineSetup;
