import React from 'react';
import { Card, ListGroup, Row, Col, Container } from 'react-bootstrap';
import InitialStateDropdown from './InitialStateDropdown';
import StateListItem from './StateListItem';

const StateSetupTab = ({ states, initialState, stateFunctions }) => {
  return (
    <Card className="text-center align-center">
      <Container>
        <Row className="row-cols-2">
          <ListGroup>
            {states.map(state => {
              return (
                <Col key={state}>
                  <StateListItem key={state} state={state} stateFunctions={stateFunctions}></StateListItem>
                </Col>
              );
            })}
          </ListGroup>
          <Col>
            <StateListItem key="new" stateFunctions={stateFunctions}></StateListItem>
            <InitialStateDropdown
              states={states}
              initialState={initialState}
              updateInitialState={stateFunctions.updateInitialState}
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default StateSetupTab;
