import { useState } from 'react';
import { Form } from 'react-bootstrap';

const MachineInput = ({ updateInput }) => {
  const [inputValue, setInputValue] = useState('00000');

  const handleChange = e => {
    setInputValue(e.target.value);
    updateInput(e.target.value);
  };
  return (
    <Form style={{ width: '50%' }} className="text-left ml-2">
      <Form.Group>
        <Form.Label>Input</Form.Label>
        <Form.Control
          type="text"
          value={inputValue}
          onChange={e => {
            handleChange(e);
          }}
        ></Form.Control>
      </Form.Group>
    </Form>
  );
};

export default MachineInput;
